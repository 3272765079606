<div class="govuk-grid-row">
  <div class="govuk-grid-column-two-thirds">
    <h1 class="govuk-heading-l govuk-!-margin-top-4">Your Workplace vacancies and turnover information</h1>
    <p class="govuk-!-padding-top-2 govuk-!-padding-bottom-1">
      Please take the time to check and update your vacancies and turnover information under Workplace in ASC-WDS.
    </p>

    <h2 class="govuk-heading-m govuk-!-margin-bottom-1">Current staff vacancies</h2>
    <p>We'll show DHSC and others how shortfalls in staffing affect the sector.</p>

    <h2 class="govuk-heading-m govuk-!-margin-bottom-1">New starters in the last 12 months</h2>
    <p>We'll learn whether the sector is attracting new staff and whether recruitment plans are working.</p>

    <h2 class="govuk-heading-m govuk-!-margin-bottom-1">Staff leavers in the last 12 months</h2>
    <p>We'll reveal staff retention issues and help DHSC and the government make policy and funding decisions.</p>

    <a [routerLink]="['/dashboard']" class="govuk-button govuk-!-margin-top-6">Continue</a>
  </div>
</div>
