<app-error-summary
  *ngIf="submitted && form.invalid"
  [formErrorsMap]="formErrorsMap"
  [serverError]="serverError"
  [form]="form"
>
</app-error-summary>

<div class="govuk-grid-row">
  <div class="govuk-grid-column-two-thirds-from-desktop">
    <form #formEl novalidate (ngSubmit)="onSubmit()" [formGroup]="form" id="server-error">
      <fieldset class="govuk-fieldset">
        <legend class="govuk-fieldset__legend govuk-fieldset__legend--l">
          <h1 class="govuk-fieldset__heading">Forgot password</h1>
        </legend>

        <p>
          Enter your username or your ASC-WDS email address (this'll be the one you used when you created your account,
          unless you've changed it) and we'll send you a link to reset your password.
        </p>

        <div
          class="govuk-form-group govuk-!-margin-bottom-7"
          [class.govuk-form-group--error]="form.get('usernameOrEmail').errors && submitted"
        >
          <label class="govuk-label" for="usernameOrEmail"> Username or email address </label>
          <span
            id="usernameOrEmail-error"
            class="govuk-error-message"
            *ngIf="form.get('usernameOrEmail').errors && submitted"
          >
            <span class="govuk-visually-hidden">Error:</span> {{ getFirstErrorMessage('usernameOrEmail') }}
          </span>
          <input
            class="govuk-input govuk-!-width-two-thirds"
            [class.govuk-input--error]="form.get('usernameOrEmail').errors && submitted"
            id="usernameOrEmail"
            name="usernameOrEmail"
            type="text"
            [formControlName]="'usernameOrEmail'"
          />
        </div>
      </fieldset>

      <div class="govuk-button-group--gap-between">
        <button type="submit" class="govuk-button">Send password reset link</button>
        <span class="govuk-visually-hidden">or</span>
        <a
          class="govuk-link govuk-link--no-visited-state govuk__nowrap"
          [routerLink]="['/login']"
          role="button"
          draggable="false"
          >Back to sign in</a
        >
      </div>
    </form>
  </div>
</div>
