<app-error-summary
  *ngIf="currentForm && currentForm.submitted && (currentForm.form.invalid || currentForm.serverError)"
  [formErrorsMap]="currentForm.formErrorsMap"
  [serverError]="currentForm.serverError"
  [form]="currentForm.form"
  [showServerErrorAsLink]="false"
>
</app-error-summary>


<h1 class="govuk-heading-l">Forgot username</h1>
<app-find-account (setCurrentForm)="setCurrentForm($event)" (accountFoundEvent)="onAccountFound($event)"/>
<ng-container *ngIf="accountUid && securityQuestion">
  <app-find-username [accountUid]="accountUid" [securityQuestion]="securityQuestion" (setCurrentForm)="setCurrentForm($event)" />
</ng-container>