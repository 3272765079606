<ng-container *ngIf="isUsernameFound; else notAvailable">
  <div
    class="govuk-panel govuk-panel--gray govuk-util__align-left govuk-!-margin-top-1 govuk-!-padding-top-1 govuk-!-padding-bottom-5 govuk-!-padding-left-2 govuk-!-padding-right-0 panel"
    data-testid="panel"
  >
    <h1 class="govuk-heading-l govuk-!-margin-top-4 govuk-!-margin-bottom-3">
      <img
        class="govuk-!-margin-right-1 govuk-util__vertical-align-top govuk-!-margin-right-2 green-tick-icon"
        src="/assets/images/tick-icon.svg"
        alt=""
      />We’ve found your username
    </h1>
    <div class="govuk-!-font-size-19 govuk-!-margin-left-7">
      Your username is
      <div class="govuk-!-font-size-27 govuk-!-font-weight-bold govuk-!-margin-top-1">{{ username }}</div>
    </div>
  </div>

  <div class="govuk-!-margin-top-8">
    <button type="submit" class="govuk-button" data-module="govuk-button" (click)="backToSignInPage($event)">
      Back to sign in
    </button>
  </div>
</ng-container>

<ng-template #notAvailable>
  <app-page-no-longer-available data-testid="page-no-longer-available"></app-page-no-longer-available>
</ng-template>
