<app-error-summary
  *ngIf="submitted && form.invalid"
  [formErrorsMap]="formErrorsMap"
  [serverError]="serverError"
  [form]="form"
>
</app-error-summary>

<div class="govuk-grid-row">
  <div class="govuk-grid-column-two-thirds">
    <form #formEl novalidate (ngSubmit)="onSubmit()" [formGroup]="form">
      <div class="govuk-form-group" [class.govuk-form-group--error]="submitted && form.invalid">
        <fieldset class="govuk-fieldset">
          <legend class="govuk-fieldset__legend govuk-fieldset__legend--l">
            <h1 class="govuk-fieldset__heading govuk__nowrap-responsive">Forgot your username or password?</h1>
          </legend>
          <p *ngIf="submitted && form.invalid" id="usernameOrPassword-error" class="govuk-error-message">
            <span class="govuk-visually-hidden">Error:</span> {{ getFirstErrorMessage('usernameOrPassword') }}
          </p>
          <div class="govuk-radios">
            <div class="govuk-radios__item">
              <input
                class="govuk-radios__input"
                id="forgotUsername"
                name="usernameOrPassword"
                formControlName="usernameOrPassword"
                type="radio"
                value="username"
              />
              <label class="govuk-label govuk-radios__label" for="forgotUsername"> Username </label>
            </div>
            <div class="govuk-radios__item">
              <input
                class="govuk-radios__input"
                id="forgotPassword"
                name="usernameOrPassword"
                formControlName="usernameOrPassword"
                type="radio"
                value="password"
              />
              <label class="govuk-label govuk-radios__label" for="forgotPassword"> Password </label>
            </div>
          </div>
        </fieldset>
      </div>

      <div class="govuk-!-padding-right-3">
        <app-details title="Forgot both?" data-testid="reveal-text">
          <p>
            <a [routerLink]="['/forgot-your-password']">Request a link to reset your password</a>
            and then come back here to find your username. Alternatively, call the ASC-WDS Support Team on
            <strong class="govuk__nowrap">0113 241 0969</strong> for help.
          </p>
        </app-details>
      </div>

      <div class="govuk-button-group--gap-between govuk-!-margin-top-8">
        <button class="govuk-button">Continue</button>
        <span class="govuk-visually-hidden">or</span>
        <a
          class="govuk-link govuk-link--no-visited-state govuk__nowrap"
          [routerLink]="['/login']"
          role="button"
          draggable="false"
          >Back to sign in</a
        >
      </div>
    </form>
  </div>
</div>
