<div
  class="govuk-error-summary"
  aria-labelledby="error-summary-title"
  role="alert"
  tabindex="-1"
  data-module="error-summary"
  data-cy="error-summary"
  #errorSummary
>
  <h2 class="govuk-error-summary__title" id="error-summary-title">There is a problem</h2>
  <div class="govuk-error-summary__body">
    <ul class="govuk-list govuk-error-summary__list">
      <ng-container *ngIf="errors">
        <ng-container *ngIf="getCurrentRoute(); let currentRoute">
          <li *ngFor="let i of errors">
            <a
              (click)="focusOnField(i.item)"
              [routerLink]="currentRoute"
              [replaceUrl]="true"
              [fragment]="getErrorId(i.item)"
              [queryParamsHandling]="'merge'"
              [innerHTML]="getFormErrorMessage(i.item, i.errors[0])"
            >
            </a>
          </li>
        </ng-container>
      </ng-container>
      <li *ngIf="serverError">
        <ng-container *ngIf="showServerErrorAsLink; else serverErrorAsPlainText">
          <a
            (click)="focusOnField('server')"
            [routerLink]="'.'"
            [fragment]="'server-error'"
            [queryParamsHandling]="'merge'"
            [innerHTML]="serverError"
          ></a>
        </ng-container>
        <ng-template #serverErrorAsPlainText>
          <p class="govuk-error-message">{{ serverError }}</p>
        </ng-template>
      </li>
      <ng-container *ngIf="customErrors">
        <li *ngFor="let customError of customErrors">
          <a
            [routerLink]="'.'"
            [fragment]="customError.name"
            [queryParamsHandling]="'merge'"
            [innerHTML]="customError.message"
          ></a>
        </li>
      </ng-container>
    </ul>
  </div>
</div>
