<div class="govuk-panel govuk-panel--with-icon govuk-!-width-two-thirds">
  <img class="govuk-!-margin-right-1" src="/assets/images/cross-icon.svg" alt="Error" />
  <h1 class="govuk-heading-l">We did not find your account</h1>
</div>

<div class="govuk-grid-row">
  <div class="govuk-grid-column-two-thirds">
    <p>Call the ASC-WDS Support Team on <strong class="govuk__nowrap">0113 241 0969</strong>.</p>
    <p class="asc__negative-margin-right-4">
      We're available Monday to Friday, 9am to 5pm (not including bank holidays).
    </p>
    <div class="govuk-!-margin-top-8">
      <a class="govuk-button govuk-button-link" [routerLink]="['/login']" draggable="false">Back to sign in</a>
    </div>
  </div>
</div>
